.brand-logo .logo{
    width: 100px;
    /* padding: 70px 50px 50px 50px; */
    margin: 35px;
    padding-top: 20px;
}

#kt_header_menu{
    display: none;
}
.head-Title{
    margin: 27px;
    width: 100%;
    text-align: left;
    color: #7E8299 !important;
    font-weight: 600 !important;
    font-size: 18px;
}

/* For SweetAlert2 Icon shape round... */
.swal2-icon{
    border-radius:50% !important;
}