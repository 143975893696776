.form-control{
    font-size: 1.21rem !important;
}

.form-control.form-control-solid.frmsubdmn{
    padding-right: 1px !important;
    background-image: none !important;
}

.spnn{
    font-size: 1.2rem;
}

.welText
{
    font-size: 1.7rem !important;
}