.zopim {
    display: none;
}
.fontstyle{
    text-align:center;
    top:15% !important;
}
.slider-item-1 .promo-like-text {
    padding: 31px 24px 12px !important;
}
.slider-item-1 .promo-like-text p{
    font-size:38px !important;
}
.imglogo{
    height: 50px;
    padding-top:7px;
}
.choose-us{
    width: 125% !important;
    margin-left: -20%;

}
.contact{
    padding: 60px 0 40px;
}
.btn.btn-primary-front {
    color: #FFFFFF !important;
    background-color: #E94D1C !important;
    border-color: #E94D1C !important;
    border-radius: 6px !important;
}

.btn.btn-primary {
    color: #FFFFFF;
    background-color: #3699FF;
    border-color: #3699FF;
    border-radius: 6px !important;
}
.btnScheduledemo{
    margin-top: 10px;
    float: right;
    margin-right: 95%;
    
}
.btn-scheduleleft
{
    float: left;
    margin-left: -29px;
    margin-top: 69px;
}
.btnContactwidth{
    width: 144px;
}
.txtScheduled{
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    float: left;
    margin-top: 31px;
}
.afbicon{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box !important;
}

.fbIcon{
    max-width: 48px;
    display: block;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    border: 0;height: auto;
    outline: none;
    text-decoration: none;
    box-sizing: border-box !important;
}
.footer ul.social-icons li a {
    opacity: unset !important;
}
.clsprivacy{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    color: #FFFFFF;
    font-weight: normal;
    text-decoration: underline;
    box-sizing: border-box !important;
}
.clsTerms{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    color: #FFFFFF;
    font-weight: normal;
    text-decoration: underline;
    box-sizing: border-box !important;
}
#menu {
    position: fixed;
    right: 0;
    top: 50%;
    width: 8em;
    margin-top: -2.5em;
  }
  .faIcon{
    display: inline-block !important;
    font: normal normal normal 14px/1 FontAwesome !important;
    font-size: inherit !important;
    text-rendering: auto !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    transform: translate(0, 0) !important;
  }
  .fixNav {
    position: fixed;
    top: 39px;
    bottom: auto;
    background: rgba(255,255,255,0.95);
}
.fixNav1{
    overflow: hidden !important;
    background-color: #FFF !important;
    position: fixed !important;
    top: 0 !important;
    width: 100% !important;

}
.tp-banner {
    width: 100% !important;
    position: relative !important;
    margin-top: 27px !important;
}
.header-navigation a {
    display: block !important;
    color: #78828c !important;
}
.header-navigation {
    float: right !important;
    list-style: none !important;
    padding: 18px 0 0 !important;
    margin: 0 !important;
    font: 400 15px "PT Sans Narrow", Arial, sans-serif !important;
    text-transform: uppercase !important;
}
.symbol.symbol-35 .symbol-label {
    width: 35px;
    height: 35px;
    border-radius: 4px !important;
} 

#kt_header .container-fluid{
    position: fixed;
    margin: 10px;
    left: 29%;
}
.btn-sm, .btn-group-sm > .btn {
    padding: 0.55rem 0.75rem;
    font-size: 0.925rem;
    line-height: 1.35;
    border-radius: 0.42rem !important;
}
 #kt_subheader .d-flex.align-items-center.flex-wrap.mr-1 {
    width: 100%;
}
.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50% !important;
    animation: spinner-border .75s linear infinite;
}
.btn {
    padding: 7px 14px;
    font-size: 14px;
    /* text-transform: uppercase; */
    border-radius: 0;
}

.offcanvas.offcanvas-right{
    right: -520px !important;
  }

  .offcanvas.offcanvas-right.offcanvas-on{
    right: 0 !important;
  }
  
  .topbar{
    padding-right: 30px !important;
  }
  
  .offcanvas{
    width: auto !important;
  }

  .hideForLaptop{
    display: none !important;
  }

  /* @media (max-width:768.98px) */
  @media (max-width:1024px)
  {
      #kt_header{
          height: 56px;
          min-height: 56px;
      }

      #kt_subheader{
          margin-top: 59px;
      }

      .logoHeightForMobile{
        height: 50px;
      }

      .hideForMobileTablet{
        display: none !important;
      }

      .showForMobileTablet{
        display: block !important;
      }

      .header{
          z-index: 0 !important;
      }
      
      .d-flex.hideForMobileTablet{
        display: none !important;
      }

      .topbar {
        padding-right: 30px;
        margin-top: 20px;
        height: 58px !important;
     }

     .head-Title{
        margin: 18px;
    	float: left;
	    width:50%;
    }

    #kt_header .container-fluid {
        width: 10%;
        position: fixed;
        margin: 0px;
        right: 0;
        top: 35px;
    }

    .header-mobile-fixed .topbar {        
        z-index: 96;
    }

    .topbar .topbar-item{
        margin-top:62px;
    }
  }